<template>
  <el-main>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="department_name"
          label="部门"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="姓名"
      >
      </el-table-column>
      <el-table-column
          prop="username"
          label="用户名"
      >
      </el-table-column>
      <el-table-column
          prop="invite_code"
          label="邀请码">
      </el-table-column>
      <el-table-column
          prop="insert_time"
          label="注册时间">
      </el-table-column>
      <el-table-column
          label="操作" align="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="deleteUser(scope)">删除用户</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
export default {
  name: "UserList",
  created() {
    let me = this;
    this.axios.get('/api/department/list').then(res => {
      res.forEach(res => {
        me.map.department['id_' + res.id] = res.name;
      })
      me.loadUserList();
    })
  },
  data() {
    return {
      map: {
        department: {}
      },
      rawData: []
    }
  },
  computed: {
    tableData() {
      let me = this;
      if (!me.rawData) {
        return [];
      }
      return me.rawData.map(val => {
        val['department_name'] = this.map.department['id_' + val['department_id']];
        return val;
      });
    }
  },
  methods: {
    loadUserList() {
      let me = this;
      this.axios.get('/api/user/list').then(res => {
        me.rawData = res;
      });
    },
    deleteUser(scope) {
      let me = this;
      this.axios.post('/api/user/delete', {id: scope.row.id}).then(() => {
        me.loadUserList();
      })
    }
  }
}
</script>

<style scoped>

</style>